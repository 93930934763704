import Layout from '@app/layouts/_layout';
import Image from 'next/image';
import HeroIcon from '@app/assets/image/hero.png';
import React, {useEffect} from 'react';
import Cookies from "js-cookie";
import Error from "@app/components/ui/error";
import Button from "@app/components/ui/button";
import {Typography} from "@mui/material";
import {useLazyGetAuthUrlQuery} from "@app/store/login/api";
import {statusErrorMessageHandler} from "@app/utils/errorUtils";
import {useRouter} from "next/router";
import FeatureCard from "@app/components/cards/feature-card";
import {useTranslation} from "next-i18next";
import {features} from "@app/constants/Features";

const Home = () => {
    const email = Cookies.get('restrictEmail');
    const {t} = useTranslation();
    const router = useRouter();
    const handleOnClick = async () => {
        try {
            await getAuthUrl(router.locale ?? "en");
        } catch (e: any) {
            console.error('error: ', e);
        }
    };
    const [getAuthUrl, data] = useLazyGetAuthUrlQuery();
    if (data.isError) {
        // @ts-ignore
        statusErrorMessageHandler(data?.error.originalStatus || data?.error.status, t)
    }

    useEffect(() => {
        if (data.isSuccess) {
            router.push(data.data.authorizationUrl);
        }
    }, [data.isSuccess]);

    if (email)
        return <Error email={email}/>;
    return (
        <Layout className="relative items-center justify-center flex h-full w-full !bg-white-200 flex-col !p-0">
            <div data-testid="landing-page"
                 className="flex h-full sm:gap-20 gap-10 sm:mt-[180px] mt-[120px] sm:mb-20 mb-10 max-w-[1440px]  w-full flex-col items-center justify-center  text-center">
                <div className="w-full flex flex-col items-center justify-center sm:gap-10 gap-5 ">
                    <Typography dangerouslySetInnerHTML={{__html: t('landingPage.heroSection.title')}}></Typography>
                    <p className="max-w-[823px] heroSubtitle !font-normal text-black-700">{t('landingPage.heroSection.subtitle')}</p>
                    <Button data-testid="get-started" onClick={handleOnClick} size="large"
                            className="!py-6 !px-8 !h-[40px] !rounded-[4px] !font-semibold">{t('getStarted')}</Button>
                    <div className="sm:mt-10 mt-5">
                        <Image alt="hero image" src={HeroIcon}/>
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <p className="h1">{t('features')}</p>
                    <div
                        className="flex  flex-wrap justify-center sm:gap-10 gap-5 sm:mt-10 mt-5 items-center">
                        {
                            features.map((feature) => <FeatureCard key={feature.title} feature={feature}/>)
                        }
                    </div>
                </div>
            </div>
            <div className="h-[60px] flex relative justify-center items-center w-full">
                <p>&#169; 2024 PDF Editor</p>
            </div>

            {/*<div*/}
            {/*    className="fixed -bottom-7 left-0 !z-10 h-[200px] w-[330px] lg:aspect-cloud-image lg:h-[302px] lg:w-[503px]">*/}
            {/*    <Image alt="backgroundIcon" layout="fill" src={BackgroundIcon}/>*/}
            {/*</div>*/}
            {/*<div*/}
            {/*    className="fixed -bottom-7 right-0 !z-10 aspect-cloud-image-small h-[200px] w-[330px] object-contain md:aspect-cloud-image lg:aspect-cloud-image lg:h-[302px] lg:w-[503px]  ">*/}
            {/*    <Image alt="backgroundIcon" src={BackgroundIcon} className="flip" layout="fill"/>*/}
            {/*</div>*/}
        </Layout>
    );
};
export {getLandingPageServerSideProps as getServerSideProps} from '@app/lib/serverSideProps';
export default Home;
